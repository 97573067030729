import React from 'react';
import '../styles/Footer.css';
import '../styles/global.css';


import logo from '../assets/logo/avanza.png';  
import iconFacebook from '../assets/rrss/icon_face.png';
import iconInstagram from '../assets/rrss/icon_ig.png';
import iconLinkedIn from '../assets/rrss/icon_in.png';
import iconWhatsApp from '../assets/rrss/icon_wsp_footer.png';

function Footer() {
    return (
        <footer className="footer">
            <div className="grupo-1">
                <div className="redes-sociales">
                    <hr />
                    <a href="https://www.facebook.com/avanza.cys" target="_blank" rel="noopener noreferrer">
                        <img src={iconFacebook} alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/avanza.cys" target="_blank" rel="noopener noreferrer">
                        <img src={iconInstagram} alt="Instagram" />
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=51971125128" target="_blank" rel="noopener noreferrer">
                        <img src={iconWhatsApp} alt="WhatsApp" />
                    </a>
                    <a href="https://www.linkedin.com/company/avanza-consulting-and-solutions" target="_blank" rel="noopener noreferrer">
                        <img src={iconLinkedIn} alt="LinkedIn" />
                    </a>
                    <hr />
                </div>
            </div>
            <div className="grupo-2">
                <div className="box-1">
                    <p>Te brinda productos y servicios de alta calidad que facilitan y apoyan las iniciativas de nuestros clientes hacia la transformación digital.</p>
                </div>
                <div className="box-2">
                    <figure>
                        <img src={logo} alt="Logo Avanza" />
                    </figure>
                </div>
            </div>
            <div className="grupo-3">
                <small>2023 avanza-com - Todos los derechos reservados</small>
            </div>
        </footer>
    );
}

export default Footer;
