import React from 'react';

import '../styles/Servicios.css';
import '../styles/global.css';

import icon_consultoria from '../assets/servicios/icon_consultoria.png';
import bg_consultoria from '../assets/servicios/bg_consultoria.png';
import icon_mobile from '../assets/servicios/icon_mobile.png';
import bg_mobile from '../assets/servicios/bg_mobile.png';
import icon_web from '../assets/servicios/icon_web.png';
import bg_web from '../assets/servicios/bg_web.png';
import icon_aseguramiento from '../assets/servicios/icon_aseguramiento.png';
import bg_aseguramiento from '../assets/servicios/bg_aseguramiento.png';
import icon_nube from '../assets/servicios/icon_nube.png';
import bg_nube from '../assets/servicios/bg_nube.png';
import icon_outsourcing from '../assets/servicios/icon_outsourcing.png';
import bg_outsourcing from '../assets/servicios/bg_outsourcing.png';
import icon_ui_ux from '../assets/servicios/icon_ui_ux.png';
import bg_ui_ux from '../assets/servicios/bg_ui_ux.png';
import icon_bi from '../assets/servicios/icon_bi.png';
import bg_bi from '../assets/servicios/bg_bi.png';
import circuloRayas from '../assets/bg/circulo_rayas.png';  

function Servicios() {
  return (
    <div className="container servicios">
      <h1 className="titulos">Nuestros servicios</h1>
      <div className="grid_container_servicios">
        {servicioData.map((servicio, index) => (
          <div key={index} className="container_servicios">
            <div className="circulos4">
              <figure>
                <img src={servicio.icon} alt={servicio.name} />
              </figure>
            </div>
            <p className="subtitulos-servicios">{servicio.name}</p>
            <p className="contenido-servicios">{servicio.description}</p>
            <div className="capa_imagen">
              <img src={servicio.backgroundImage} className="img_servicios" alt="" />
            </div>
          </div>
        ))}
      </div>

      <figure className="circulo_raya">
        <img src={circuloRayas} alt="Decorativo circulo con rayas" />
      </figure>
    </div>
  );
}

const servicioData = [
    {
      name: "Consultoría Digital",
      description: "Ofrecemos servicio de consultoría para ayudar a nuestros clientes a alcanzar sus metas mediante estrategias eficaces y resultados rápidos",
      icon: icon_consultoria,
      backgroundImage: bg_consultoria
    },
    {
      name: "Desarrollo móvil",
      description: "Nos enfocamos en la calidad del producto a través de proyectos ágiles para conectar con usuarios tempranos y permitir la mejora continua.",
      icon: icon_mobile,
      backgroundImage: bg_mobile
    },
    {
      name: "Desarrollo web",
      description: "Ofrecemos servicios de desarrollo web de calidad, desde aplicativos simples hasta plataformas modernas, distribuidas y escalables.",
      icon: icon_web,
      backgroundImage: bg_web
    },
    {
      name: "Aseguramiento de calidad",
      description: "Proporcionamos un equipo dedicado a tu proyecto para realizar pruebas y acompañarte hasta su finalización exitosa.",
      icon: icon_aseguramiento,
      backgroundImage: bg_aseguramiento
    },
    {
      name: "Servicios en la nube",
      description: "Ayudamos a nuestros clientes a adaptarse a las últimas tendencias tecnológicas con modelos en la nube para aumentar la capacidad y escalabilidad de su infraestructura.",
      icon: icon_nube,
      backgroundImage: bg_nube
    },
    {
      name: "Outsourcing de TI",
      description: "A través del Outsourcing de personal técnico calificado, tus procesos serán más eficientes, mientras nosotros ideamos y gestionamos todos los pasos del proceso para ti.",
      icon: icon_outsourcing,
      backgroundImage: bg_outsourcing
    },
    {
      name: "Servicio de UX/UI",
      description: "Diseñamos soluciones efectivas basadas en procesos de diseño UX/UI y definición de productos, incorporando feedback de usuarios finales y stakeholders para asegurar eficiencia y éxito en cada proyecto",
      icon: icon_ui_ux,
      backgroundImage: bg_ui_ux
    },
    {
      name: "Business Intelligence",
      description: "Descubre el impacto transformador de nuestro servicio de Business Intelligence (BI), diseñado para convertir tus datos en decisiones estratégicas.",
      icon: icon_bi,
      backgroundImage: bg_bi
    }
  ];
  

export default Servicios;
