import React from 'react';
import '../styles/Beneficios.css';
import '../styles/global.css';

import personaTabletImage from '../assets/persona_tablet.png';
import medioCirculo from '../assets/bg/medio_circulo_hueco.png';


function Beneficios() {
    return (
        <div className="container beneficios">
            <div className="persona_tablet"
                 style={{
                     backgroundImage: `url(${personaTabletImage})`,
                 }}>
                <p className="texto_imagen_beneficio">
                    <strong>Beneficios de Implementar</strong> soluciones en tu empresa
                </p>
            </div>
            <div className="grid_container_beneficios">
                {beneficiosData.map((beneficio) => (
                    <div key={beneficio.id}>
                        <div className="circulos1">
                            <div className="circulos2">
                                <div className="circulos3">{beneficio.id}</div>
                            </div>
                        </div>
                        <p className="subtitulos">{beneficio.titulo}</p>
                        <p className="contenido">{beneficio.descripcion}</p>
                    </div>
                ))}
            </div>
            <figure className="circulo_hueco">
                <img  src={medioCirculo} alt="Circulo decorativo" />
            </figure>
        </div>
        
    );
}

const beneficiosData = [
    {
        id: 1,
        titulo: "Software a la medida de tu empresa",
        descripcion: "El software a medida mejora la eficiencia, personalización y control en los procesos de negocio de su empresa. Déjenos ayudarlo a hacer realidad su visión y a impulsar el crecimiento de su negocio."
    },
    {
        id: 2,
        titulo: "Automatiza los procesos y aumenta la eficiencia",
        descripcion: "La automatización de procesos es clave para el éxito empresarial en la era digital. A través de nuestros servicios, su empresa puede mejorar la eficiencia, aumentar la productividad y reducir errores."
    },
    {
        id: 3,
        titulo: "Reduce la cantidad de recursos humanos y materiales",
        descripcion: "Invierte solo en lo más adecuado al presupuesto y necesidades de tu organización."
    },
    {
        id: 4,
        titulo: "Acelera el crecimiento de tu empresa",
        descripcion: "Al optimizar las operaciones clave, gana tiempo y dinero."
    }
];
export default Beneficios;
