import React from 'react';
import '../styles/Clientes.css'; 

import logoProInvesting from '../assets/clientes/pro-investing.png';
import logoAgepaf from '../assets/clientes/agepaf.png';
import logoAddera from '../assets/clientes/addera.png';
import logoCalendarCook from '../assets/clientes/calendar-cook.png';

function Clientes() {
  return (
    <div className="container clientes">
      <h1 className="titulos">Nuestros clientes</h1>
      <p className="contenido" style={{ justifyContent: 'center', textAlign: 'center' }}>
        Trabajamos con pasión y propósito junto con nuestros clientes,
        integrando enfoques innovadores y experiencia profunda.
      </p>

      <div className="container_slider">
        <div className="container_card_cliente">
          <div className="contenido_clientes">
            <img src={logoProInvesting} className="logos_clientes" alt="Pro Investing" />
          </div>
          <div className="contenido_clientes">
            <img src={logoAgepaf} className="logos_clientes" alt="Agepaf" />
          </div>
          <div className="contenido_clientes">
            <img src={logoAddera} className="logos_clientes" alt="Addera" />
          </div>
          <div className="contenido_clientes">
            <img src={logoCalendarCook} className="logos_clientes" alt="Calendar Cook" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clientes;
