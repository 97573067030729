import React from 'react';
import '../styles/AsWork.css';

import iconAdaptabilidad from '../assets/trabajo/icon_adaptabilidad.png';
import iconMetodologia from '../assets/trabajo/icon_metodologia.png';
import iconGrupoPersonas from '../assets/trabajo/icon_grupo_personas.png';
import iconComunicacion from '../assets/trabajo/icon_comunicacion.png';
import iconInnovacion from '../assets/trabajo/icon_innovacion.png';
import iconCalidad from '../assets/trabajo/icon_calidad.png';

function AsWork() {
  return (
    <div className="container as-work">
      <h1 className="titulos"><strong>¿Cómo trabajamos en Avanza?</strong></h1>
      <div className="grid_container_work">
        {trabajos.map((trabajo, index) => (
          <div key={index} className="grid-item">
            <img className="icons-as-work" src={trabajo.icon} alt={trabajo.alt} />
            <p className="subtitulos">{trabajo.title}</p>
            <p className="contenido">{trabajo.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const trabajos = [
  {
    icon: iconAdaptabilidad,
    title: "Adaptabilidad",
    description: "Nos adaptamos rápidamente a los cambios del mercado para ofrecer soluciones innovadoras a nuestros clientes",
    alt: "Se muestra un icono de persona con elementos a su alrededor"
  },
  {
    icon: iconMetodologia,
    title: "Metodología",
    description: "Lo más importante está en elegir la técnica más adecuada para cada proyecto",
    alt: "Se muestra una camino que al final tiene un check"
  },
  {
    icon: iconGrupoPersonas,
    title: "El usuario es lo primero",
    description: "Nos dedicamos a entender las necesidades de nuestros clientes para ofrecer soluciones personalizadas y satisfacer sus expectativas al máximo.",
    alt: "Se muestra 3 iconos de personas"
  },
  {
    icon: iconComunicacion,
    title: "Comunicación",
    description: "Nos enfocamos en escuchar sus necesidades y adaptar nuestro lenguaje para garantizar una comunicación clara y efectiva en todo momento",
    alt: "Se muestra una lupa y una bombilla"
  },
  {
    icon: iconInnovacion,
    title: "Innovación",
    description: "Siempre estamos explorando nuevas tecnologías y enfoques para ofrecer soluciones creativas y personalizadas que aborden los desafíos empresariales únicos de nuestros clientes.",
    alt: "Se muestra la cabeza de una personas con iconos de tuercas"
  },
  {
    icon: iconCalidad,
    title: "Calidad",
    description: "Aseguramos que nuestros productos sean confiables, seguros y fáciles de usar, para brindar la mejor experiencia a nuestros clientes",
    alt: "Se muestra una mano recibiendo un símbolo de dinero"
  }
];


export default AsWork;
