import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules'; 

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';  


import '../styles/Cards.css'; 
import '../styles/global.css'; 

import transparencia from '../assets/valores/transparencia.svg';
import mejoramientoContinuo from '../assets/valores/mejoramiento-continuo.svg';
import trabajoEnEquipo from '../assets/valores/trabajo-en-equipo.svg';
import profesionalismo from '../assets/valores/profesionalismo.svg';
import integridad from '../assets/valores/integridad.svg';
import eficiencia from '../assets/valores/eficiencia.svg';
import arrowLeft from '../assets/flecha_izquierda.png';
import arrowRight from '../assets/flecha_derecha.png';

const cardData = [
  { image: transparencia, title: "Transparencia" },
  { image: mejoramientoContinuo, title: "Mejoramiento Continuo" },
  { image: trabajoEnEquipo, title: "Trabajo en Equipo" },
  { image: profesionalismo, title: "Profesionalismo" },
  { image: integridad, title: "Integridad" },
  { image: eficiencia, title: "Eficiencia" },
];

function Cards() {
  const swiperRef = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.update();
          // swiperRef.current.swiper.init(); 
        }
      };

      // handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className="container slider_cont">
      <div class="medio_circulo"></div>
      <div className="cards_swiper_valores container_card">
        <Swiper
          modules={[Navigation, Autoplay]}  
          // navigation={{
          //   nextEl: '.cards_swiper-button-next',
          //   prevEl: '.cards_swiper-button-prev',
          // }}
          spaceBetween={33}
          slidesPerView={1}
          loop={true}
          className="cards_swiper-wrapper"
          autoplay={{  
            delay: 700,  
            disableOnInteraction: false 
          }}
          speed={600} 
          effect="slide" // 'slide', 'fade', 'cube', 'coverflow' o 'flip'
          breakpoints={{
            320: { slidesPerView: 1, navigation: true },
            640: { slidesPerView: 2, spaceBetween: 20 },  
            768: { slidesPerView: 2, spaceBetween: 30 },  
            992: { slidesPerView: 3, navigation: true },
            1200: { slidesPerView: 6, navigation: false }
          }}
        >
          {cardData.map((card, index) => (
            <SwiperSlide key={index} className="cards_card_primary cards_itemCarrouselTarjeta">
              <img src={card.image} alt={card.title} />
              <var><abbr>{card.title}</abbr></var>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="cards_itemCarrouselArrows">
          <div className="cards_swiper-button-prev">
            <img src={arrowLeft} alt="Previous" />
          </div>
          <div className="cards_swiper-button-next">
            <img src={arrowRight} alt="Next" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
