// import logo from './logo.svg';
import React from 'react';

import Header from './components/Header';
import Cards from './components/Cards';
import AsWork from './components/AsWork';
import Beneficios from './components/Beneficios';
import Servicios from './components/Servicios';
import Clientes from './components/Clientes';
import Contacto from './components/Contacto';
import Footer from './components/Footer';


function App() {
    return (
        <div>
            <Header /> 
            <Cards/>
            <AsWork/>
            <Beneficios />
            <Servicios />
            <Clientes />
            <Contacto />
            <Footer />
        </div>
    );
}

export default App;

