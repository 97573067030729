import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import '../styles/Header.css';
import '../styles/global.css';

// Asset imports
import logo from '../assets/logo/avanza.png';
import iconFacebook from '../assets/rrss/icon_face.png';
import iconInstagram from '../assets/rrss/icon_ig.png';
import iconWhatsApp from '../assets/rrss/icon_wsp_footer.png';
import iconLinkedIn from '../assets/rrss/icon_in.png';
import arrowLeft from '../assets/flecha_izquierda.png';
import arrowRight from '../assets/flecha_derecha.png';
import backgroundImage1 from '../assets/banner/persona_codigo.png';
import backgroundImage2 from '../assets/banner/person_computador.png';
import backgroundImage3 from '../assets/banner/codigo.png';

export default function Header() {
  return (
    <div className="swiper_banner carrousel_home">
      <div className="logos_header">
        <img src={logo} className="logo_avanza" alt="Logo Avanza" />

        <div className="redes_sociales_header">
          <a href="https://www.facebook.com/avanza.cys" target="_blank" rel="noopener noreferrer">
            <img src={iconFacebook} alt="Facebook Icon" />
          </a>
          <a href="https://www.instagram.com/avanza.cys" target="_blank" rel="noopener noreferrer">
            <img src={iconInstagram} alt="Instagram Icon" />
          </a>
          <a href="https://api.whatsapp.com/send/?phone=51971125128" target="_blank" rel="noopener noreferrer">
            <img src={iconWhatsApp} alt="WhatsApp Icon" />
          </a>
          <a href="https://www.linkedin.com/company/avanza-consulting-and-solutions" target="_blank" rel="noopener noreferrer">
            <img src={iconLinkedIn} alt="LinkedIn Icon" />
          </a>
        </div>
      </div>

      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: '.header_swiper-button-next',
          prevEl: '.header_swiper-button-prev',
        }}
        loop={true}
        slidesPerView={1}
        className='swiper-header'
      >
        <SwiperSlide style={{ backgroundImage: `url(${backgroundImage1})`, backgroundSize: 'cover' }}>
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>Innovación y eficiencia</b> con nuestras soluciones de software
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${backgroundImage2})`, backgroundSize: 'cover' }}>
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>Convierte tus ideas en realidad</b> con nuestras soluciones de software a medida
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${backgroundImage3})`, backgroundSize: 'cover' }}>
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>Convierte los desafíos</b> en oportunidades con nuestras soluciones tecnológicas innovadoras
            </p>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="itemCarrouselArrows">
        <div className="header_swiper-button-prev">
          <img src={arrowLeft} alt="Previous" />
        </div>
        <div className="header_swiper-button-next">
          <img src={arrowRight} alt="Next" />
        </div>
      </div>

    </div>
  );
}